










































import Avatar from '@/modules/community/common/components/avatar.vue'
import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { CommunityDetailViewModel } from '../../viewmodels/community-detail-viewmodel'
import { walletStore } from '@/stores/wallet-store'

@Observer
@Component({
  components: {
    Avatar,
    'follow-button': () => import('@/modules/common/components/follow-button.vue'),
  },
})
export default class TopContributors extends Vue {
  @Inject({}) vm!: CommunityDetailViewModel
  walletStore = walletStore
}
